export enum UserKeycloakRoles {
  STOCK_ADMIN = 'stock_admin',

  STOCK_MANAGER = 'stock_manager',
  STOCK_OPERATOR = 'stock_operator',
}

export const UserKeycloakRolesLabel = {
  [UserKeycloakRoles.STOCK_ADMIN]: 'Administrateur',
  [UserKeycloakRoles.STOCK_MANAGER]: 'Responsable de stock',
  [UserKeycloakRoles.STOCK_OPERATOR]: 'Opérateur',
}
